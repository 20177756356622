@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.animate-fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

.square-box {
    width: 200px;
    /* Dilediğiniz genişlik değerini verebilirsiniz */
    height: 200px;
    /* Genişlik değeri ile aynı olan bir yükseklik değeri seçin */
}

@keyframes expand-center {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.animate-expand-center {
    animation: expand-center 0.5s cubic-bezier(0.36, 0.55, 0.19, 1);
}